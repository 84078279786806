<template>
    <Component
        :is="href ? 'a' : 'button'"
        class="button"
        :class="{
            'disabled': disabled,
            'w-full md:w-auto': smallExpanded,
            'w-full': largeExpanded,
            large,
            small,
            xs,
            secondary,
        }"
        :disabled="disabled"
        :href="href"
        :rel="href ? 'noopener' : ''"
    >
        <slot></slot>
    </Component>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    mounted() {
    },
    props: {
        disabled: Boolean,
        href: String,
        smallExpanded: Boolean,
        largeExpanded: Boolean,
        large: Boolean,
        small: Boolean,
        xs: Boolean,
        secondary: Boolean,
    },
    methods: {
    },
    created() {
    },
    computed: {
    },
    watch: {
    }
};
</script>

<style lang="postcss" scoped>
</style>
